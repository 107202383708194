<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h2>
                Lead - {{ dados.nomecontato }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card-body>
                <dl class="row">
                  <dt class="col-sm-3">
                    Nome:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.nomecontato }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    E-mail:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.emailcontato }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    Obs:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.observacaolead }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    Produto de Interesse:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.produtosinteresse }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    UTM Med:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_medium }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    UTM Term:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_term }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    Telefone:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.telefonecontato }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    Horario:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.melhorhorarioctt }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    UTM Orig:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_source }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-4">
                    UTM Camp:
                  </dt>
                  <dd class="col-sm-8">
                    {{ dados.utm_campaign }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-3">
                    UTM Cont:
                  </dt>
                  <dd class="col-sm-9">
                    {{ dados.utm_content }}
                  </dd>
                </dl>

              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      categoriaTipo: '',
      nomeLead: '',
      telefoneLead: '',
      emailLead: '',
      descLead: '',
      instaLead: '',
      faceLead: '',
      cepLead: '',
      enderecoLead: '',
      numeroLead: '',
      bairroLead: '',
      estadoLead: 1,
      cidadeLead: 1,
      url: 'admin/lead',
      data: {},
      dadositem: {},
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
    }
  },
  created() {

  },
  methods: {
    ZerarCampos() {
      const obj = {
        nome: this.nomelead,
        email: this.emaillead,
        descricao: this.descLead,
        instagram: this.instaLead,
        facebook: this.faceLead,
        cep: this.cepLead,
        telefone: this.telefoneLead,
        endereco: this.enderecoLead,
        numero: this.numeroLead,
        bairro: this.bairroLead,
        id_estado: this.estadoLead,
        id_cidade: this.cidadeLead,
        imagem: this.imagemLead,
        status: this.categoriaTipo.value,
      }
      this.nomeLead = null
      this.emailLead = null
      this.descLead = null
      this.instaLead = null
      this.faceLead = null
      this.cepLead = null
      this.enderecoLead = null
      this.numeroLead = null
      this.bairroLead = null
      this.estadoLead = null
      this.cidadeLead = null
      this.imagemLead = null
      this.categoriaTipo = null
      return obj
    },
    SalvarDados() {
      const obj = this.ZerarCampos()
      if (this.id === null) {
        this.$http.post('admin/lead', obj).then(resp => {
          this.data = resp.data
          this.reloadTable()
        })
        return
      }
      this.$http.post('admin/lead', obj).then(resp => {
        this.data = resp.data
        this.reloadTable()
      })
    },
    editDados(item) {
      localStorage.setItem('lead', JSON.stringify(item))
      this.$store.state.categoria = item
      this.$router.push({
        name: 'app-novolead',
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'light-danger', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Ativo' }
      }
      if (item.status === 0) {
        return { cor: 'light-warning', msg: 'Fornecedor' }
      } return 'Sem Status'
    },
    reloadTable(tableProps) {
      this.BuscarDados(this.url, tableProps)
    },
  },
}
</script>
